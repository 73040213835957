<template>
  <v-card style="height: 275px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center">
        <v-col cols="auto">
          <span class="text-h6 font-weight-regular">
            {{ selectedStation.rastless_product.toUpperCase() }} -
            {{ $t("baseline.singleValues") }}</span
          >
        </v-col>
        <v-col
          class="pl-0"
          cols="auto"
          v-if="!loading"
          style="min-width: 200px;"
        >
          <v-switch
            :label="
              showInSituData
                ? $t('inSitu.dashboard.hideInSituData')
                : $t('inSitu.dashboard.showInSituData')
            "
            color="primary"
            hide-details
            :disabled="!hasInSituData"
            v-model="showInSituData"
            class="mt-0 pa-0 float-left text-body-2"
          ></v-switch>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pr-0" cols="auto">
          <v-tooltip top v-if="editMode">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!oldMarkedTimesteps.length"
                :color="oldMarkedTimesteps.length ? 'primary' : ' grey'"
                class="px-1 text-lowercase"
                dark
                icon
                text
                v-bind="attrs"
                v-on="on"
                @click="resetAllTimesteps"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("baseline.resetChanges") }}</span>
          </v-tooltip>
          <v-tooltip top v-if="editMode">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!markedTimesteps.length"
                :color="markedTimesteps.length ? 'primary' : ' grey'"
                class="px-3 text-lowercase"
                text
                v-bind="attrs"
                v-on="on"
                @click="saveMarkedTimesteps"
              >
                {{ $t("baseline.deleteSelected") }}
              </v-btn>
            </template>
            <span>{{ $t("baseline.deleteSelectedPoints") }}</span>
          </v-tooltip>

          <v-btn
            v-model="editMode"
            color="grey"
            class="px-0 text-lowercase"
            dark
            text
            @click="toogleEdit"
            v-if="!editMode"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
            {{ $t("baseline.edit") }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="px-0 text-lowercase"
            dark
            text
            @click="toogleEdit"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-col
          class="pl-0"
          cols="auto"
          v-if="!loading"
          style="min-width: 100px;"
        >
          <period-filter
            :years="showInSituData ? combinedYears : years"
            @filteredYears="changeYears"
            @filteredPeriod="changeTimePeriod"
          ></period-filter>
        </v-col>
      </v-row>
    </v-card-text>
    <v-row class="px-3">
      <div
        ref="plot"
        id="singleValuesPlot"
        :style="{
          top: 0,
          bottom: 0,
          width: 'calc(78% - 10px)',
          height: '200px',
          marginRight: '10px'
        }"
      ></div>
      <div
        ref="boxPlot"
        id="singleValuesBoxPlot"
        :style="{
          top: 0,
          bottom: 0,
          width: '22%',
          height: '200px'
        }"
      ></div>
      <v-overlay v-if="loading" opacity="0.1" absolute>
        <div>
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </div>
      </v-overlay>
    </v-row>
  </v-card>
</template>

<script>
import PeriodFilter from "@/core/components/baseline/PeriodFilter.vue";
import plotly from "plotly.js-dist";
import baselineMixin from "@/core/mixins/baseline.mixin";
import dataManagementMixin from "@/core/mixins/dataManagement.mixin";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import inSituDataMixin from "@/core/mixins/inSituData.mixin";

export default {
  name: "SingleValues",
  components: { PeriodFilter },
  mixins: [baselineMixin, dataManagementMixin, inSituDataMixin],
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    years: { type: Array },
    reload: { type: Boolean }
  },
  data: () => ({
    data: {},
    timeseries: null,
    loading: true,
    plotData: null,
    boxPlotData: null,
    editMode: false,
    markedTimesteps: [],
    addedTimestep: [],
    oldMarkedTimesteps: [],
    drawSnackbar: false,
    dataToPlots: null,
    combinedYears: [],
    showInSituData: false,
    inSituPlotData: [],
    inSituBoxPlotData: [],
    yearsToFilterInSituData: [],
    periodToFilterInSituData: [],
    filterBy: [],
    hasInSituData: false
  }),
  computed: {
    ...mapState("baseline", ["selectedStation", "color1", "color2"]),
    ...mapState("inSitu", ["singleColorInSituData"])
  },
  methods: {
    ...mapActions("app", ["showSnackbar", "hideSnackbar"]),
    async saveMarkedTimesteps() {
      await this.markTimestepsAsDeleted(
        this.activeRegion.id,
        this.selectedStation.rastless_layer_id,
        this.selectedStation.virtual_station_id,
        this.markedTimesteps
      );
      this.editMode = false;
      this.hideSnackbar();
      this.reloadTimeseries();
    },
    async resetAllTimesteps() {
      await this.resetMarkedTimesteps(
        this.selectedStation.rastless_layer_id,
        this.selectedStation.virtual_station_id
      );
      this.reloadTimeseries();
    },
    reloadTimeseries() {
      this.loading = true;
      this.fetchAllStatistics(
        this.activeRegion.id,
        this.selectedStation.id
      ).then(async timeseries => {
        this.timeseries = timeseries;
        this.oldMarkedTimesteps = await this.getMarkedTimesteps(
          this.activeRegion.id,
          this.selectedStation.rastless_layer_id,
          this.selectedStation.virtual_station_id
        );
        this.loading = false;
        this.editMode = false;
        this.hideSnackbar();
        this.createPlot(this.timeseries.datetime, this.timeseries.data);
      });
      this.$emit("reload");
    },
    toogleEdit() {
      if (this.editMode) {
        this.editMode = false;
        this.hideSnackbar();
        const restore = {
          marker: {
            color: "#40ADD5",
            size: 8
          }
        };
        let plotDiv = document.getElementById("singleValuesPlot");
        plotDiv.removeAllListeners("plotly_click");
        plotly.restyle("singleValuesPlot", restore, [0]);
      } else {
        this.markedTimesteps = [];
        this.editMode = true;
        this.showSnackbar({
          show: true,
          message: this.$t("baseline.selectPoints"),
          color: "primary",
          timeout: -1
        });
        const update = {
          marker: {
            color: "#40ADD5",
            line: {
              color: "black",
              width: 0.5
            },
            size: 8
          }
        };
        plotly.restyle("singleValuesPlot", update, [0]);
        this.addClickEvent();
      }
    },
    addClickEvent() {
      var plotDiv = document.getElementById("singleValuesPlot");
      const mode = this.editMode;
      let colors = [];
      let addedTimestep = this.addedTimestep;
      plotDiv.on("plotly_click", data => {
        let point = data.points[0].data;
        if (
          (mode &&
            !Object.prototype.hasOwnProperty.call(point, "customdata")) ||
          !point.customdata[0] === "sourceInSituData"
        ) {
          const index = data.points[0].pointIndex;
          addedTimestep.push(data.points[0].data.x[index]);
          data.points[0].data.x.forEach((point, index) => {
            if (colors?.length == data.points[0].data.x.length) {
              colors[index] = data.points[0].data.marker.color[index];
            } else {
              colors.push(data.points[0].data.marker.color);
            }
          });
          if (colors[index] == "#40ADD5") {
            colors[index] = "#F6A124";
          } else {
            colors[index] = "#40ADD5";
          }
          var update = {
            marker: {
              color: colors,
              line: {
                color: "black",
                width: 0.5
              },
              size: 8
            }
          };
          plotly.restyle(plotDiv, update, [0]);
        } else {
          this.showSnackbar({
            show: true,
            message: this.$t("inSitu.plotsPointOnClickInfo"),
            color: "error"
          });
        }
      });
    },
    updatePlot(data, layout) {
      plotly.react("singleValuesPlot", data, layout);
    },
    changeYears(years) {
      this.yearsToFilterInSituData = years;

      let datetimes = [];
      let values = [];
      this.timeseries.datetime.forEach((datetime, index) => {
        if (years.includes(datetime.split("-")[0])) {
          datetimes.push(datetime);
          values.push(this.timeseries.data[index]);
        }
      });
      this.createPlot(datetimes, values);
      this.createBoxPlot(values);

      // filter by years - In Situ Data Plot
      let datetimesInSituData = [];
      let valuesInSituData = [];
      this.dataToPlots.date_times.forEach((datetime, index) => {
        if (years.includes(datetime.split("-")[0])) {
          datetimesInSituData.push(datetime);
          valuesInSituData.push(this.dataToPlots.values[index]);
        }
      });
      if (this.showInSituData) {
        this.createInSituPlot(datetimesInSituData, valuesInSituData);
        this.createInSituBoxPlot(valuesInSituData);
        this.checkShowThresholds();
      }
    },
    changeTimePeriod(timePeriod) {
      this.periodToFilterInSituData = timePeriod;
      let datetimes = [];
      let values = [];
      this.timeseries.datetime.forEach((datetime, index) => {
        if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
          datetimes.push(datetime);
          values.push(this.timeseries.data[index]);
        }
      });
      this.createPlot(datetimes, values);
      this.createBoxPlot(values);
      // filter by date - In Situ Data Box Plot
      let datetimesInSituData = [];
      let valuesInSituData = [];
      this.dataToPlots.date_times.forEach((datetime, index) => {
        if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
          datetimesInSituData.push(datetime);
          valuesInSituData.push(this.dataToPlots.values[index]);
        }
      });
      if (this.showInSituData) {
        this.createInSituPlot(datetimesInSituData, valuesInSituData);
        this.createInSituBoxPlot(valuesInSituData);
        this.checkShowThresholds();
      }
    },
    createPlot(datetimes, values) {
      this.plotData = {
        type: "scatter",
        mode: "markers",
        marker: { color: this.color2, size: 8 },
        x: datetimes,
        y: values
      };
      const config = this.getPLotConfigs();
      this.layout = this.getLayout(this.selectedStation.rastless_unit);
      var plotDiv = document.getElementById("singleValuesPlot");
      plotly.newPlot(plotDiv, [this.plotData], this.layout, config);
    },
    createBoxPlot(values) {
      this.boxPlotData = {
        type: "box",
        y: values,
        name: this.selectedStation.station_name,
        marker: {
          color: this.color2
        }
      };
      const config = this.getPLotConfigs();
      this.boxlayout = {
        margin: { autoexpand: false, l: 45, r: 20, b: 30, t: 15, pad: 0 },
        showlegend: false
      };

      plotly.newPlot(
        "singleValuesBoxPlot",
        [this.boxPlotData],
        this.boxlayout,
        config
      );
    },
    createInSituPlot(datetimes, values) {
      this.inSituPlotData = {
        type: "scatter",
        mode: "markers",
        marker: {
          color: this.singleColorInSituData,
          size: 8,
          symbol: "diamond"
        },
        x: datetimes,
        y: values,
        customdata: ["sourceInSituData"]
      };
      var plotDiv = document.getElementById("singleValuesPlot");
      plotly.addTraces(plotDiv, [this.inSituPlotData]);
    },
    createInSituBoxPlot(values) {
      this.inSituBoxPlotData = {
        type: "box",
        y: values,
        name: this.$t("inSitu.baselineInSituPlotTitle"),
        marker: {
          color: "#173466",
          symbol: "diamond"
        }
      };
      plotly.addTraces("singleValuesBoxPlot", [this.inSituBoxPlotData]);
    },

    removeTraceByCustomData(customDataValue) {
      const plotContainer = this.$refs.plot;

      let traceIndicesToRemove = [];

      if (plotContainer && plotContainer.data) {
        plotContainer.data.forEach((trace, index) => {
          if (trace.customdata && trace.customdata.includes(customDataValue)) {
            traceIndicesToRemove.push(index);
            plotly.deleteTraces("singleValuesPlot", traceIndicesToRemove);
            plotly.deleteTraces("singleValuesBoxPlot", 1);
          }
        });
      }
    },
    filterPlotDataByDateOrYear(plotData, filterBy) {
      const isDateRange = filterBy.length === 2 && filterBy[0].includes("-");
      let datetimesInSituData = [];
      let valuesInSituData = [];

      if (isDateRange) {
        plotData.date_times.forEach((datetime, index) => {
          if (moment(datetime).isBetween(filterBy[0], filterBy[1])) {
            datetimesInSituData.push(datetime);
            valuesInSituData.push(plotData.values[index]);
          }
        });
        this.createInSituPlot(datetimesInSituData, valuesInSituData);
        this.createInSituBoxPlot(valuesInSituData);
      } else {
        plotData.date_times.forEach((datetime, index) => {
          if (filterBy.includes(datetime.split("-")[0])) {
            datetimesInSituData.push(datetime);
            valuesInSituData.push(plotData.values[index]);
          }
        });

        this.createInSituPlot(datetimesInSituData, valuesInSituData);
        this.createInSituBoxPlot(valuesInSituData);
      }
    },
    checkStateOfInSituDataVisibility() {
      const plotContainer = this.$refs.plot;

      if (plotContainer && plotContainer.data) {
        this.hasInSituData = plotContainer.data.some(
          trace =>
            trace.customdata &&
            trace.customdata.includes("sourceInSituData") &&
            trace.x.length
        );
        console.log(plotContainer.data[1]);
        console.log(this.hasInSituData);

        if (this.showInSituData) {
          if (!this.hasInSituData) {
            plotly.addTraces("singleValuesPlot", this.inSituPlotData);
            plotly.addTraces("singleValuesBoxPlot", this.inSituBoxPlotData);

            let lay = { xaxis: { autorange: true }, autosize: true };
            plotly.relayout(plotContainer, lay);
          }
        } else {
          if (this.hasInSituData) {
            this.removeTraceByCustomData("sourceInSituData");
          }
        }
      }
    },

    checkShowThresholds() {
      if (this.showThresholds) {
        if (this.thresholds.local_thresholds !== null) {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.local_thresholds.threshold_alert,
            this.thresholds.local_thresholds.threshold_warning
          );
        } else {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.global_thresholds.threshold_alert,
            this.thresholds.global_thresholds.threshold_warning
          );
        }
        if (!this.showInSituData) {
          this.updatePlot([this.plotData], this.layout);
        } else {
          this.updatePlot([this.plotData, this.inSituPlotData], this.layout);
        }
      } else {
        this.layout = this.getLayout(this.selectedStation.rastless_unit);
        if (!this.showInSituData) {
          this.updatePlot([this.plotData], this.layout);
        } else {
          this.updatePlot([this.plotData, this.inSituPlotData], this.layout);
        }
      }
    }
  },

  watch: {
    selectedStation() {
      this.reloadTimeseries();
    },
    showThresholds() {
      this.checkShowThresholds();
    },
    addedTimestep() {
      const newTimestep = this.addedTimestep[this.addedTimestep.length - 1];
      if (this.markedTimesteps.includes(newTimestep)) {
        const index = this.markedTimesteps.indexOf(newTimestep);
        this.markedTimesteps.splice(index, 1);
      } else [this.markedTimesteps.push(newTimestep)];
    },
    showInSituData(value) {
      if (!value) {
        this.removeTraceByCustomData("sourceInSituData");
      } else {
        if (this.filterBy.length <= 0) {
          this.filterBy = this.combinedYears;
        }

        this.filterPlotDataByDateOrYear(this.dataToPlots, this.filterBy);
      }
    },
    yearsToFilterInSituData() {
      this.filterBy = this.yearsToFilterInSituData;
    },
    periodToFilterInSituData() {
      this.filterBy = this.periodToFilterInSituData;
    }
  },
  async created() {
    const [
      fetchAllStatistics,
      fetchInSituStationsData
    ] = await Promise.allSettled([
      this.fetchAllStatistics(this.activeRegion.id, this.selectedStation.id),
      this.fetchInSituStationsData(
        this.selectedStation.virtual_station_id,
        this.selectedStation.rastless_layer_id
      )
    ]);

    if (fetchAllStatistics.status === "fulfilled") {
      this.timeseries = fetchAllStatistics.value;
      this.loading = false;
      this.oldMarkedTimesteps = await this.getMarkedTimesteps(
        this.activeRegion.id,
        this.selectedStation.rastless_layer_id,
        this.selectedStation.virtual_station_id
      );
      this.createPlot(this.timeseries.datetime, this.timeseries.data);
      this.createBoxPlot(this.timeseries.data);
    } else {
      this.showSnackbar({
        show: true,
        message: "Error in fetching statistics",
        color: "error"
      });
    }
    if (fetchInSituStationsData.status === "fulfilled") {
      this.dataToPlots = fetchInSituStationsData.value;
      this.loading = false;
      //update years, combining years from props and insitu data
      const yearsFromData = this.dataToPlots.date_times.map(
        dateString => dateString.split("-")[0]
      );
      const uniqueYearsSet = new Set([...this.years, ...yearsFromData]);
      this.combinedYears = Array.from(uniqueYearsSet).sort((a, b) => a - b);

      this.createInSituPlot(
        this.dataToPlots.date_times,
        this.dataToPlots.values
      );
      this.createInSituBoxPlot(this.dataToPlots.values);
      this.checkStateOfInSituDataVisibility();
    } else {
      this.showSnackbar({
        show: true,
        message: "Error in fetching in situ data",
        color: "error"
      });
    }
  }
};
</script>

<style scoped></style>
